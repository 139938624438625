import type * as React from 'react';

import { useLoginState } from '@xing-com/crate-hooks-use-login-state';
import { XingletLoader } from '@xing-com/crate-xinglet';

export const Container: React.FC = () => {
  const { isLoggedOut } = useLoginState();

  if (isLoggedOut) {
    return (
      <XingletLoader name="@xing-com/crate-profile-main-loader-logged-out" />
    );
  } else {
    return <XingletLoader name="@xing-com/crate-profile-main-logged-in" />;
  }
};
